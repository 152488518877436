import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  removeAccessToken,
  removeRefreshAccessToken
} from 'services/common/storage';
import { getCustomerProfile } from 'services/customers';
import { CustomerProfileType } from 'services/customers/types';

interface AuthState {
  profile?: CustomerProfileType;
  isLoading: boolean;
}

const initialState: AuthState = {
  profile: undefined,
  isLoading: false,
};

export const getProfileAction = createAsyncThunk<CustomerProfileType>(
  'profileReducer/getCustomerProfile',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getCustomerProfile();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const authSlice = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
    resetAuth($state) {
      $state.profile = undefined;
      removeAccessToken();
      removeRefreshAccessToken();
    },
    updateProfile($state, action: PayloadAction<CustomerProfileType>) {
      $state.profile = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getProfileAction.pending, ($state) => {
      $state.isLoading = true;
    });
    builder.addCase(getProfileAction.fulfilled, ($state, action) => {
      $state.profile = action.payload;
      $state.isLoading = false;
    });
    builder.addCase(getProfileAction.rejected, ($state) => {
      $state.isLoading = false;
    });
  },
});

export const {
  resetAuth,
  updateProfile,
} = authSlice.actions;

export default authSlice.reducer;
