export const LOCAL_STORAGE = {
  ACCESS_TOKEN: 'AZHOPE-ACCESS-TOKEN',
  REFRESH_TOKEN: 'AZHOPE-REFRESH-TOKEN',
};

export const CONSTANT_ROUTES = {
  HOME: '/',
  LOGIN: '/dang-nhap',
  REGISTER: '/dang-ky',
  MY_PROFILE: '/trang-ca-nhan',
  VERIFY_ACCOUNT: '/verify-account',
  FORGOT_PASSWORD: '/quen-mat-khau',
  RESET_PASSWORD: '/verify',
  EXAM: '/kiem-tra',
  COURSE: '/khoa-hoc',
  ABOUT: '/about',
  PROFILE: '/profile',
  CONTACT: '/contact',
};

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const DEFAULT_QUERY_OPTION = {
  retry: 0,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};

export const ERROR_CODE_SUPPORTED: GlobalErrorCode[] = [400, 403, 404, 429, 500];

export const graduateOptions = [
  { label: 'Đại học', value: 1 },
  { label: 'Cao đẳng', value: 2 },
  { label: 'Trung cấp', value: 3 },
];
