import {
  AuthResTypes,
  ChangePasswordParamTypes,
  CustomerProfileType,
  LoginParamsTypes,
  UpdateCustomerProfileType
} from './types';

import axiosInstance from 'services/common/instance';

export const loginService = async (params: LoginParamsTypes): Promise<AuthResTypes> => {
  const res = await axiosInstance.post('customers/login', params);
  return res.data.data;
};

export const getCustomerProfile = async (): Promise<CustomerProfileType> => {
  const res = await axiosInstance.get('customers/profile');
  return res.data.data;
};

export const activeAccountService = async (email: string, code: string): Promise<void> => {
  await axiosInstance.post('customers/active', { email, code });
};

export const updateCustomerProfileService = async (
  params: UpdateCustomerProfileType
): Promise<CustomerProfileType> => {
  const res = await axiosInstance.put('customers/update-profile', params);
  return res.data.data;
};

export const postUploadAvatarService = async (file: File): Promise<CustomerProfileType> => {
  const formData = new FormData();
  formData.append('thumbnail', file);

  const res = await axiosInstance.post('customers/upload-avatar', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return res.data.data;
};

export const changePasswordService = async (params: ChangePasswordParamTypes): Promise<void> => {
  await axiosInstance.post('customers/change-password', params);
};
