/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Suspense, useRef } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import './App.scss';
import {
  BrowserRouter, Route, Routes
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Loading as LoadingComponent } from 'tfc-components';
import 'react-toastify/dist/ReactToastify.css';

import Loading from 'components/atoms/Loading';
import MainLayout from 'components/templates/MainLayout';
import useInitializeRender from 'hooks/useInitializeRender';
import ProtectedRoute from 'navigations/ProtectedRoute';
import CommingSoon from 'pages/CommingSoon';
import useRoutesList from 'routes';
import { store } from 'store';

// const App: React.FC = () => {
//   const loading = useInitializeRender();
//   const { routes } = useRoutesList();
//   if (loading) {
//     return <Loading isShow variant="fullScreen" />;
//   }
//   return (
//     <Suspense fallback={<LoadingComponent.CircleDashed color="#fff" width={60} />}>
//       <Routes>
//         <Route element={<MainLayout />}>
//           {(Object.keys(routes) as Array<keyof typeof routes>).map(
//             (ele, index) => {
//               if (routes[ele] && routes[ele].childrens) {
//                 return (
//                   <Route key={`router-${ele}-${index.toString()}`} element={routes[ele].element}>
//                     {routes[ele].childrens?.map((child, childIndex) => (
//                       <Route
//                         key={`router-${ele}-${childIndex.toString()}`}
//                         path={child.path}
//                         element={child.isProtected ? (
//                           <ProtectedRoute>
//                             {child.element}
//                           </ProtectedRoute>
//                         ) : child.element}
//                       />
//                     ))}
//                   </Route>
//                 );
//               }
//               return (
//                 <Route
//                   key={`router-${ele}-${index.toString()}`}
//                   path={routes[ele].path}
//                   element={routes[ele].isProtected ? (
//                     <ProtectedRoute>
//                       {routes[ele].element}
//                     </ProtectedRoute>
//                   ) : routes[ele].element}
//                 />
//               );
//             }
//           )}
//         </Route>
//       </Routes>
//     </Suspense>
//   );
// };

const App: React.FC = () => (
  <Routes>
    <Route path="*" element={<CommingSoon />} />
  </Routes>
);

const AppProvider: React.FC = () => {
  const queryClient = useRef(new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  }));
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient.current}>
        <HelmetProvider>
          <BrowserRouter>
            <App />
            <ToastContainer />
          </BrowserRouter>
        </HelmetProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default AppProvider;
