import {
  AuthResTypes,
  LoginParamsTypes,
  ProfileDataType,
  RequestUserProfileTypes,
  ResetPasswordType,
  ResponseForgotPassword,
  SignUpTypes
} from './types';

import axiosInstance from 'services/common/instance';
import { getRefreshToken } from 'services/common/storage';

export const updateUserProfile = async (reqData: RequestUserProfileTypes): Promise<void> => {
  await axiosInstance.post('accounts/profile', reqData);
};

export const registerService = async (reqData: SignUpTypes): Promise<void> => {
  await axiosInstance.post('customers/register', reqData);
};

export const loginService = async (params: LoginParamsTypes): Promise<AuthResTypes> => {
  const res = await axiosInstance.post('accounts/login', params);
  return res.data.data;
};

export const logoutService = async (): Promise<void> => {
  await axiosInstance.post('customers/logout');
};

export const getProfileService = async (): Promise<ProfileDataType> => {
  const res = await axiosInstance.get('accounts/profile');
  return res.data.data;
};

export const forgotPasswordService = async (params: {
  email: string;
}): Promise<ResponseForgotPassword> => {
  const res = await axiosInstance.post('customers/forgot-password', params);
  return res.data.data;
};

export const resetPasswordService = async (params: ResetPasswordType): Promise<void> => {
  await axiosInstance.post('customers/reset-password', params);
};

export const refreshTokenService = async (): Promise<AuthResTypes> => {
  const res = await axiosInstance.post('customers/refresh-token', { refreshToken: getRefreshToken() });
  return res.data.data;
};
